import * as THREE from "three";


import { TeethNumbering } from "./teethParser";
import { MeshLine, MeshLineMaterial } from "meshline";

let controls;

let manifestVersion;
let IPRdata;

let cameraZoom = 7.5;

let zoomValue = function () {
  if (window.innerWidth > 1900) {
    if (cameraZoom !== 10) return;
    else cameraZoom = 10;
    camera.zoom = cameraZoom;
    camera.updateProjectionMatrix();
  } else {
    if (cameraZoom !== 7.5) return;
    else cameraZoom = 7.5;
    camera.zoom = cameraZoom;
    camera.updateProjectionMatrix();
  }
};

const group = new THREE.Group();
const group2 = new THREE.Group();

const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.01, 1000);
const camera2 = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.01, 1000);
let scene = new THREE.Scene();
const scene2 = new THREE.Scene();

//lights
const color = 0xffffff;
const intensity = 1;
const light = new THREE.DirectionalLight(color, intensity);
light.position.set(0.25, 3, -2.25);
const light2 = new THREE.PointLight(color, 1);
light2.power = 25;
const light3 = new THREE.DirectionalLight(color, intensity);
light3.position.set(0.25, 3, -2.25);
const light4 = new THREE.PointLight(color, 1);
light4.power = 25;

//sets camera to front view;
camera.position.z = 60;
camera.position.y = 0;
camera.position.x = 0;
camera.zoom = cameraZoom;
camera2.position.z = 60;
camera2.position.y = 0;
camera2.position.x = 0;
camera2.zoom = cameraZoom;

export const views = (action, isSplit) => {
  switch (action) {
    case "front":
      group.rotation.x = 0;
      group.rotation.y = 0;
      group.rotation.z = 0;
    
      if (isSplit) {
        group2.rotation.x = 0;
        group2.rotation.y = 0;
        group2.rotation.z = 0;
    
      }
      break;
    case "left":
      group.rotation.x = 0;
      group.rotation.y = -1.5;
      group.rotation.z = 0;
    
      if (isSplit) {
        group2.rotation.x = 0;
        group2.rotation.y = -1.5;
        group2.rotation.z = 0;
     
      }
      break;
    case "right":
      group.rotation.x = 0;
      group.rotation.y = 1.5;
      group.rotation.z = 0;
    
      if (isSplit) {
        group2.rotation.x = 0;
        group2.rotation.y = 1.5;
        group2.rotation.z = 0;
     
      }
      break;
    case "upper":
      group.rotation.x = -1.5;
      group.rotation.y = 0;
      group.rotation.z = 0;
   
      if (isSplit) {
        group2.rotation.x = -1.5;
        group2.rotation.y = 0;
        group2.rotation.z = 0;
   
      }
      break;
    case "lower":
      group.rotation.x = 1.5;
      group.rotation.y = 0;
      group.rotation.z = 0;
   
      if (isSplit) {
        group2.rotation.x = 1.5;
        group2.rotation.y = 0;
        group2.rotation.z = 0;
     
      }
      break;
    default:
      group.rotation.x = 0;
      group.rotation.y = 0;
      group.rotation.z = 0;
      group.children.forEach((mesh) => {
        mesh.visible = true;
      });
      if (isSplit) {
        group2.rotation.x = 0;
        group2.rotation.y = 0;
        group2.rotation.z = 0;
        group2.children.forEach((mesh) => {
          mesh.visible = true;
        });
      }
  }
};

function dollyIn(dollyScale) {
  if (camera.type === "OrthographicCamera") {
    cameraZoom = cameraZoom + 1;
    camera.zoom = cameraZoom;
    camera.updateProjectionMatrix();
  } else {
    console.warn(
      "WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled."
    );
  }
}

function dollyOut(dollyScale) {
  if (camera.type === "OrthographicCamera") {
    cameraZoom = cameraZoom - 1;
    camera.zoom = cameraZoom;
    camera.updateProjectionMatrix();
  } else {
    console.warn(
      "WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled."
    );
  }
}

function resetZoom() {
  cameraZoom = 5;
}

//a data structure we use to instruct 'render' function to eventually update
//scene
const sceneInfo = {
  dirty: false, //means cleanup and load following data
  activeMeshes: [], //the meshes to be loaded
  currentMeshes: [], //thew meshes to be removed
  currentLastMeshes: [], //thew meshes to be removed
  firstStepMeshes: [],
  superImposeDirty: false,
  removeFirstSteps: false,
  isIpr: false,
  isShowNumbers: false,
  superImposeActive: [],
  step: 0,
  beforeAndAfterDirty: false,
  beforeAndAfterActive: [],
  isLast: false,
  isBeforeAndAfter: false,
  cursor: "grab",
  isGrid: false
};

//set the meshes to display
let setMeshes = function (
  meshes,
  isSuperImpose,
  isIpr,
  isShowNumbers,
  meshesForFirstStep,
  n,
  lastStepMeshes,
  isBeforeAndAfter,
  cursor, 
  isGrid
) {
  // console.log("step ", n);
  sceneInfo.activeMeshes = meshes;
  sceneInfo.dirty = true;
  sceneInfo.superImposeActive = meshesForFirstStep;
  sceneInfo.isIpr = isIpr;
  sceneInfo.isShowNumbers = isShowNumbers;
  sceneInfo.superImposeDirty = isSuperImpose ? true : false;
  sceneInfo.removeFirstSteps = isSuperImpose ? false : true;
  sceneInfo.step = n;
  sceneInfo.beforeAndAfterDirty = isBeforeAndAfter ? true : false;
  sceneInfo.beforeAndAfterActive = lastStepMeshes;
  sceneInfo.isSplit = isBeforeAndAfter;
  sceneInfo.cursor = cursor;
  sceneInfo.isGrid = isGrid
};

let changeSceneBackgroundColor = (canvas, isDarkMode) => {
  // const renderer = new THREE.WebGLRenderer({ canvas, alpha: true });
  // if (isDarkMode) {
  //   renderer.setClearColor("#1c1c1c", 1);
  // } else {
  //   renderer.setClearColor("#eeedeb", 1);
  // }
};

function getCenterPoint(mesh) {
  let geometry =
    mesh instanceof THREE.Mesh || mesh.isMesh ? mesh.geometry : mesh;
  geometry.computeBoundingSphere();
  const middle = geometry.boundingSphere.center;
  return middle;
}

/* ************************************************************************************** */

function makeLabelCanvas(
  baseWidth,
  size,
  text,
  textColor,
  bgColor,
  borderColor,
  lablePos
) {
  text = " " + text + " ";
  const borderSize = 0;
  const ctx = document.createElement("canvas").getContext("2d");
  const font = `bold ${lablePos === "ipr" ? size / 1.5 : size}px 'Arial'`;
  ctx.font = font;
  // measure how long the text will be
  const textWidth = ctx.measureText(text).width;

  const doubleBorderSize = borderSize * 2;
  const width = baseWidth + doubleBorderSize;
  const height = size + doubleBorderSize;
  ctx.canvas.width = width;
  ctx.canvas.height = height;

  // //just to see the whole canvas
  // ctx.fillStyle = 'red';
  // ctx.fillRect(0, 0, width, height);

  // need to set font again after resizing canvas
  ctx.font = font;
  ctx.textBaseline = "middle";
  ctx.textAlign = "center";

  ctx.scale(0.5, 0.5);
  ctx.translate(height, lablePos === "ipr" ? width / 2 : width);
  lablePos !== "ipr" && ctx.rotate(Math.PI / 4.0); // make it rect
  ctx.translate(-width / 2, -height / 2);

  ctx.scale(1.4, 1.4);
  ctx.translate(-50, -50);

  ctx.fillStyle = borderColor || "lightgray";
  ctx.fillRect(0, 0, width, height);

  ctx.fillStyle = bgColor || "gray";
  ctx.fillRect(10, 10, width - 20, height - 20);
  lablePos !== "ipr" && ctx.rotate(-Math.PI / 4.0); // make it rect

  // scale to fit but don't stretch
  const scaleFactor = Math.min(1, baseWidth / textWidth);
  lablePos === "ipr"
    ? ctx.translate(width, 150)
    : ctx.translate(width / 2, height / 2);
  ctx.scale(scaleFactor, 1);

  ctx.scale(0.8, 0.8);
  ctx.translate(-450, 100);
  ctx.fillStyle = textColor || "white";

  ctx.fillText(text, 10, 20);

  return ctx.canvas;
}

function makeSpriteLabel(
  position,
  labelWidth,
  size,
  text,
  colors,
  anchorOnTop,
  lablePos
) {
  const canvas = makeLabelCanvas(
    labelWidth,
    size,
    text,
    colors.textColor,
    colors.bgColor,
    colors.borderColor,
    lablePos
  );
  const texture = new THREE.CanvasTexture(canvas);
  // because our canvas is likely not a power of 2
  // in both dimensions set the filtering appropriately.
  texture.minFilter = THREE.LinearFilter;
  texture.wrapS = THREE.ClampToEdgeWrapping;
  texture.wrapT = THREE.ClampToEdgeWrapping;

  const labelMaterial = new THREE.SpriteMaterial({
    map: texture,
    // side: THREE.DoubleSide,
    transparent: true,
  });

  const root = new THREE.Object3D();
  root.position.copy(position);

  const bodyHeight = 2;
  const bodyRadiusTop = 0.4;

  // const labelGeometry = new THREE.PlaneBufferGeometry(1, 1);

  const label = new THREE.Sprite(labelMaterial);
  root.add(label);
  label.position.y = anchorOnTop ? (-bodyHeight * 4) / 5 : (bodyHeight * 4) / 5;
  label.position.z = bodyRadiusTop * 1.01;

  // if units are meters then 0.01 here makes size
  // of the label into centimeters.
  const labelBaseScale = 0.01;
  label.scale.x = canvas.width * labelBaseScale;
  label.scale.y = canvas.height * labelBaseScale;

  scene.add(root);
  return root;
}
function getNearestPoints(position, teethList) {
  const ta = teethList[position];
  const tb = teethList[position + 1];
  console.log("get nearest point between ", ta.name, tb.name);
  if (ta && tb) {
    if (!ta.nearestPointToNext || !tb.nearestPointToPrev) {
      const na = ta.geometry.attributes.position.count;
      const va = ta.geometry.attributes.position.array;
      const nb = tb.geometry.attributes.position.count;
      const vb = tb.geometry.attributes.position.array;
      let posA = 0;
      let mind = Number.MAX_VALUE;
      let mini = -1;
      let minj = -1;
      for (let i = 0; i < na; i++) {
        posA = i * 3;
        const xa = va[posA];
        const ya = va[1 + posA];
        const za = va[2 + posA];
        let posB = 0;
        for (let j = 0; j < nb; j++) {
          posB = j * 3;
          const xb = vb[posB];
          const yb = vb[1 + posB];
          const zb = vb[2 + posB];
          const dx = xa - xb;
          const dy = ya - yb;
          const dz = za - zb;
          const dab = dx * dx + dy * dy + dz * dz;
          if (dab < mind) {
            //console.log("got a min of", dab, "at", i,j);
            mind = dab;
            mini = i;
            minj = j;
          }
        }
      }
      console.log("MIN:", mind, "at", mini, minj);
      const xa = va[3 * mini];
      const ya = va[3 * mini + 1];
      const za = va[3 * mini + 2];
      const xb = vb[3 * minj];
      const yb = vb[3 * minj + 1];
      const zb = vb[3 * minj + 2];
      // {
      //   const geometry = new THREE.SphereGeometry(1, 32, 16);
      //   const material = new THREE.MeshBasicMaterial({color: 0xf0000FF});
      //   const sphere = new THREE.Mesh(geometry, material);
      //
      //   sphere.translateOnAxis(new THREE.Vector3(xa, ya, za), 1);
      //   scene.add(sphere);
      //   sceneInfo.currentMeshes.push(sphere);
      // }
      // {
      //   const geometry = new THREE.SphereGeometry(1, 32, 16);
      //   const material = new THREE.MeshBasicMaterial({color: 0xfFF00FF});
      //   const sphere = new THREE.Mesh(geometry, material);
      //
      //   sphere.translateOnAxis(new THREE.Vector3(xb, yb, zb), 1);
      //   scene.add(sphere);
      //   sceneInfo.currentMeshes.push(sphere);
      // }
      // {
      //   const geometry = new THREE.SphereGeometry(1, 32, 16);
      //   const material = new THREE.MeshBasicMaterial({color: 0xfFF00FF});
      //   const sphere = new THREE.Mesh(geometry, material);
      //
      //   sphere.translateOnAxis(new THREE.Vector3((xa+xb)/2.0, (ya+yb)/2.0, (za+zb)/2.0), 1);
      //   scene.add(sphere);
      //   sceneInfo.currentMeshes.push(sphere);
      // }
      //cache the results in the teeth
      ta.nearestPointToNext = new THREE.Vector3(xa, ya, za);
      tb.nearestPointToPrev = new THREE.Vector3(xb, yb, zb);
    } else {
      console.log("neatest values cached");
    }
    return [ta.nearestPointToNext, tb.nearestPointToPrev];
  }
}

function placeIPRLabelsBetweenTeeth(teeth, step) {
  let labels = [];
  teeth.forEach((t, idx) => {
    const toothNumber = t.name; //a bit misleading, the filed name contains the tooth number :|
    const numberingData = TeethNumbering.byNumber(toothNumber);
    let toothIprData;
    let isLower;
    if (numberingData.name.startsWith("lower")) {
      isLower = true;
      toothIprData = IPRdata.lower_teeth[numberingData.name];
    } else {
      isLower = false;
      toothIprData = IPRdata.upper_teeth[numberingData.name];
    }

    if (toothIprData && toothIprData.use_ipr == true) {
      const ipr_steps_amounts = toothIprData.ipr_steps_amounts;
      const at_current_step = ipr_steps_amounts.find((e) => e.step == step);
      if (at_current_step) {
        // console.log("at step ", step, " found ipr: ", at_current_step.amount, "on tooth ", numberingData.name)

        // const tc0 = getCenterPoint(teeth[idx]);
        // const tc1 = getCenterPoint(teeth[idx + 1]); //this is not true IMO TODO

        const [tc0, tc1] = getNearestPoints(idx, teeth);
        // console.log("NPS", tc0, tc1);
        const tc = new THREE.Vector3(
          (tc0.x + tc1.x) / 2.0,
          (tc0.y + tc1.y) / 2.0,
          (tc0.z + tc1.z) / 2.0
        );

        const n = new THREE.Vector3();
        n.copy(tc);
        n.normalize();

        const amount = 14;

        const labelpos = new THREE.Vector3(
          tc.x + amount * n.x,
          tc.y + (isLower ? -0.8 : +0.8) * amount,
          tc.z + amount * n.z
        );

        const label = makeSpriteLabel(
          labelpos,
          700,
          500,
          /*numberingData.displayName*/ at_current_step.amount,
          {
            textColor: "rgba(46, 46, 47, 0.8)",
            bgColor: "white",
            borderColor: "white",
          },
          isLower,
          "ipr"
        );

        const line = createLine(tc, labelpos, "white");

        labels.push(label);
        labels.push(line);
      }
      if (step === 0) {
        const tc0 = getCenterPoint(teeth[idx]);
        const tc1 = getCenterPoint(teeth[idx + 1]); //this is not true IMO TODO

        // const [tc0, tc1] = getNearestPoints(idx, teeth);
        const tc = new THREE.Vector3(
          (tc0.x + tc1.x) / 2.0,
          (tc0.y + tc1.y) / 2.0,
          (tc0.z + tc1.z) / 2.0
        );

        const n = new THREE.Vector3();
        n.copy(tc);
        n.normalize();

        const amount = 14;

        const labelpos = new THREE.Vector3(
          tc.x + amount * n.x,
          tc.y + (isLower ? -0.8 : +0.8) * amount,
          tc.z + amount * n.z
        );

        if (ipr_steps_amounts[0]?.amount) {
          const label = makeSpriteLabel(
            labelpos,
            700,
            500,
            /*numberingData.displayName*/ ipr_steps_amounts[0]?.amount,
            {
              textColor: "rgba(46, 46, 47, 0.8)",
              bgColor: "white",
              borderColor: "white",
            },
            isLower,
            "ipr"
          );

          const line = createLine(tc, labelpos, "white");

          labels.push(label);
          labels.push(line);
        }
      } else {
        // console.log("at step ", step, " NOT found ipr");
      }
    }
  });

  return labels;
}

/* ****************************************************************************/
// function placeSpheresBetweenTooth(teeh) {
//
//   //centers
//   const centers = [];
//
//   teeh.forEach( t => {
//     const tc = getCenterPoint(t);
//     centers.push(tc)
//   })
//
//   let prev = centers[0];
//   let middles = [];
//   for(let i =1; i < centers.length; i++) {
//     let cur = centers[i];
//     middles.push( new THREE.Vector3((cur.x+prev.x)/2.0,(cur.y+prev.y)/2.0,(cur.z+prev.z)/2.0 ) )
//
//     prev = cur
//   }
//
//   let spheres = []
//
//   middles.forEach( (bs) => {
//     const geometry = new THREE.SphereGeometry(1, 32, 16);
//     const material = new THREE.MeshBasicMaterial({color: 0xf0000FF});
//     const sphere = new THREE.Mesh(geometry, material);
//     sphere.translateOnAxis(bs, 1);
//     sphere.translateOnAxis(new THREE.Vector3(bs.x, 0, bs.z), 0.05);
//     spheres.push(sphere)
//   })
//
//   return spheres;
//
// }

/* ****************************************************************************/
//
// function placeLabelsOnTeethPerStep(teeh,step) {
//
//
//   //centers
//   const centers = [];
//
//   teeh.forEach( t => {
//     const tc = getCenterPoint(t);
//     centers.push(tc)
//   })
//
//   let labels = []
//   centers.forEach( (tc,i) => {
//
//     const toothNumber  = teeh[i].name; //a bit misleading, the filed name contains the tooth number :|
//
//     const numberingData = TeethNumbering.byNumber(toothNumber)
//
//     console.log("processing tooth", toothNumber, "aka", numberingData)
//
//     let toothIprData;
//     if(numberingData.name.startsWith('lower')) {
//       toothIprData = IPRdata.lower_teeth[numberingData.name]
//     } else {
//       toothIprData = IPRdata.upper_teeth[numberingData.name]
//     }
//     console.log("-x----", toothIprData.use_ipr)
//     console.log("-----", toothIprData)
//
//
//     if(toothIprData && toothIprData.use_ipr == true) {
//       const ipr_steps_amounts = toothIprData.ipr_steps_amounts;
//       const at_current_step = ipr_steps_amounts.find( (e) => e.step == step);
//       if(at_current_step) {
//         console.log("at step ", step, " found ipr: ", at_current_step.amount, "on tooth ", numberingData.name)
//
//         const n = new THREE.Vector3();
//         n.copy(tc)
//         n.normalize();
//
//         const labelpos = new THREE.Vector3(tc.x+8*n.x,tc.y,tc.z+8*n.z);
//
//         const label = makeSpriteLabel(labelpos, 320, 320, at_current_step.amount, "black");
//         labels.push(label)
//       } else
//         console.log("at step ", step, " NOT found ipr");
//     }
//
//   })
//
//   return labels;
//
// }

function placeLabelsOnTeeth(teeh) {
  //centers
  const centers = [];
  teeh.forEach((t) => {
    const tc = getCenterPoint(t);
    centers.push(tc);
  });

  let labels = [];
  centers.forEach((tc, i) => {
    const toothNumber = teeh[i].name; //a bit misleading, the filed name contains the tooth number :|
    const numberingData = TeethNumbering.byNumber(toothNumber);

    const n = new THREE.Vector3();
    n.copy(tc);
    n.normalize();

    const isLower = numberingData?.name?.startsWith("lower");

    let amount = 10.0;

    const labelpos = new THREE.Vector3(
      tc.x + amount * n.x,
      tc.y + (isLower ? -0.8 : +0.8) * amount,
      tc.z + amount * n.z
    );
    const label = makeSpriteLabel(
      labelpos,
      320,
      320,
      numberingData?.displayName,
      { textColor: "black", bgColor: "gray", borderColor: "lightGray" },
      isLower,
      "tooth"
    );
    labels.push(label);

    // const sphere_geometry = new THREE.SphereGeometry(.15,32,16);
    // const material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
    // const sphere = new THREE.Mesh( sphere_geometry, material );
    // sphere.position.copy(labelpos)
    // labels.push(sphere)

    const line = createLine(tc, labelpos);

    labels.push(line);
  });

  return labels;
}

let createLine = function (p0, p1, color) {
  const samples = [p0, p1];
  // const material = new THREE.LineBasicMaterial( { color: 0xffffff, /*dashSize: 1, gapSize: 0.5,*/ linewidth: 3} );
  // const line = new THREE.Line( geometry, material );
  // line.computeLineDistances();

  const geometry = new THREE.BufferGeometry().setFromPoints(samples);
  const line = new MeshLine();
  line.setGeometry(geometry);
  const material = new MeshLineMaterial({
    useMap: false, //tells the material to use map (0 - solid color, 1 use texture)
    color: color,
    opacity: 0.38,
    resolution: new THREE.Vector2(window.innerWidth, window.innerHeight), //THREE.Vector2 specifying the canvas size (REQUIRED)
    sizeAttenuation: false, // makes the line width constant regardless distance (1 unit is 1px on screen) (0 - attenuate, 1 - don't attenuate)
    transparent: true,
    lineWidth: 5,
    depthTest: true,
    alphaTest: 0, //cutoff value from 0 to 1
  });

  /* If you're rendering transparent lines or using a texture with alpha map, you should set
  depthTest to false, transparent to true and blending to an appropriate blending mode, or use alphaTest.*/

  const mesh = new THREE.Mesh(line.geometry, material);
  return mesh;
};
let helperGrid = new THREE.GridHelper(500, 10, "black" , 'black')
helperGrid.rotation.x = 1.5;
helperGrid.position.z = -2


let helperGrid2 = new THREE.GridHelper(500, 100)
helperGrid2.rotation.x = 1.5;
helperGrid2.position.z = -5
let helperGrid3 = new THREE.GridHelper(500, 10, "black" , 'black')
helperGrid3.rotation.x = 1.5;
helperGrid3.position.z = -2


let helperGrid4 = new THREE.GridHelper(500, 100)
helperGrid4.rotation.x = 1.5;
helperGrid4.position.z = -5
helperGrid.visible= false
helperGrid2.visible= false
helperGrid3.visible= false
helperGrid4.visible= false

scene2.add(helperGrid3);
scene2.add(helperGrid4);
scene.add(helperGrid);
scene.add(helperGrid2);

let handleGridSize = (zoom) => {
  if (zoom <= 75) {
    helperGrid.scale.x = 1.5
    helperGrid.scale.y = 1.5
    helperGrid.scale.z = 1.5
  
    helperGrid2.scale.x = 1.5
    helperGrid2.scale.y = 1.5
    helperGrid2.scale.z = 1.5
    helperGrid3.scale.x = 1.5
    helperGrid3.scale.y = 1.5
    helperGrid3.scale.z = 1.5
  
    helperGrid4.scale.x = 1.5
    helperGrid4.scale.y = 1.5
    helperGrid4.scale.z = 1.5
  } else if (zoom > 75 && zoom <= 250) {
    helperGrid.scale.x = 1
    helperGrid.scale.y = 1
    helperGrid.scale.z = 1
  
    helperGrid2.scale.x = 1
    helperGrid2.scale.y = 1
    helperGrid2.scale.z = 1
    helperGrid3.scale.x = 1
    helperGrid3.scale.y = 1
    helperGrid3.scale.z = 1
  
    helperGrid4.scale.x = 1
    helperGrid4.scale.y = 1
    helperGrid4.scale.z = 1
  }
  else if (zoom > 250) {
    helperGrid.scale.x = 0.5
    helperGrid.scale.y = 0.5
    helperGrid.scale.z = 0.5
  
    helperGrid2.scale.x = 0.5
    helperGrid2.scale.y = 0.5
    helperGrid2.scale.z = 0.5
    helperGrid3.scale.x = 0.5
    helperGrid3.scale.y = 0.5
    helperGrid3.scale.z = 0.5
  
    helperGrid4.scale.x = 0.5
    helperGrid4.scale.y = 0.5
    helperGrid4.scale.z = 0.5
  }

}


let resetPosition = () => {
    group.position.x = 0;
    group.position.y = 0;
    group.position.z = -50;
    group2.position.x = 0;
    group2.position.y = 0;
    group2.position.z = -50;
    group.rotation.x = 0;
    group.rotation.y = 0;
    group2.rotation.x = 0;
    group2.rotation.y = 0;
};
let initialize = function (canvas, { action }, tsOptions, version, IPRs) {
  if (canvas === null) {
    return;
  }
  manifestVersion = version;
  IPRdata = IPRs;

  const renderer = new THREE.WebGLRenderer({ canvas, alpha: true, antialias: true });

  camera.add(light);
  camera.add(light2);
  camera2.add(light3);
  camera2.add(light4);

  scene.add(camera);

  scene2.add(camera2);

  var mouseDown = false,
    mouseX = 0,
    mouseY = 0;

  function onMouseMove(evt) {
    if (!mouseDown) {
      return;
    }

    evt.preventDefault();

    var deltaX = evt.clientX - mouseX,
      deltaY = evt.clientY - mouseY;
    mouseX = evt.clientX;
    mouseY = evt.clientY;
    rotateScene(deltaX, deltaY);
  }

  function onMouseDown(evt) {
    evt.preventDefault();

    mouseDown = true;
    mouseX = evt.clientX;
    mouseY = evt.clientY;
  }

  function onMouseUp(evt) {
    evt.preventDefault();

    mouseDown = false;
  }

  renderer.domElement.addEventListener(
    "mousemove",
    function (e) {
      onMouseMove(e);
    },
    false
  );
  renderer.domElement.addEventListener(
    "mousedown",
    function (e) {
      onMouseDown(e);
    },
    false
  );
  renderer.domElement.addEventListener(
    "mouseup",
    function (e) {
      onMouseUp(e);
    },
    false
  );

  function rotateScene(deltaX, deltaY) {
    if (sceneInfo.cursor === "grab") {
      group.rotation.y += deltaX / 100;
      group.rotation.x += deltaY / 100;
      if (sceneInfo.isSplit) {
        group2.rotation.y += deltaX / 100;
        group2.rotation.x += deltaY / 100;
      }
    }
    if (sceneInfo.cursor === "move") {
      group.position.y -= deltaY / 4;
      group.position.x += deltaX / 4;
      if (sceneInfo.isSplit) {
        group2.position.y -= deltaY / 4;
        group2.position.x += deltaX / 4;
      }
    }
  }
  if (sceneInfo.isSplit) {
    resetPosition();
  } else {
    resetPosition();
  }

  function render() {
    let resizePOS = window.innerWidth < 840 ? 1.5 : 2; // onDesktop = 2 || on mobile = 1.5
    let resizeMOS = window.innerWidth < 840 ? -1.5 : -2; // onDesktop = -2 || on mobile = -1.5

    // if (resizeRendererToDisplaySize(renderer) ) {
    const pixelRatio = window.devicePixelRatio;
    const canvas = renderer.domElement;
    const width = sceneInfo.isSplit
      ? canvas.clientWidth / 2
      : canvas.clientWidth;
    const height = canvas.clientHeight;
    camera.left = width / resizeMOS;
    camera.right = width / resizePOS;
    camera.top = height / resizePOS;
    camera.bottom = height / resizeMOS;
    renderer.physicallyCorrectLights = true;
    renderer.setSize(
      (canvas.clientWidth * pixelRatio) | 0,
      (canvas.clientHeight * pixelRatio) | 0,
      false
    );
    camera.updateProjectionMatrix();

    if (sceneInfo.removeFirstSteps) {
      //cleanup & lpoad new meshes
      sceneInfo.firstStepMeshes.forEach((m) => group.remove(m)); //pop out
      sceneInfo.firstStepMeshes = []; //promote active to current
      sceneInfo.superImposeActive = [];
      sceneInfo.superImposeDirty = false;
      sceneInfo.removeFirstSteps = false;
    }

    if (sceneInfo.dirty) {
      //cleanup & load new meshes
      sceneInfo.currentMeshes.forEach((m) => group.remove(m)); //pop out
      sceneInfo.currentMeshes = sceneInfo.activeMeshes; //promote active to current
      sceneInfo.activeMeshes = [];
      sceneInfo.currentMeshes.forEach((m, i) => {
        if (m.material) m.material.shininess = 0;
        else if (m.children) {
          //TODO filter for teeth only (not needed now but it would be futureproof)
          m.children.forEach((cm) => {
            cm.material.shininess = 0;
          });

          if (manifestVersion >= 2) {
            if (sceneInfo.isShowNumbers) {
              const labels = placeLabelsOnTeeth(m.children);
              labels.forEach((l) => {
                group.add(l);
                sceneInfo.currentMeshes.push(l);
              });
            }
            if (sceneInfo.isIpr) {
              const iprs = placeIPRLabelsBetweenTeeth(
                m.children,
                sceneInfo.step
              );
              iprs.forEach((l) => {
                group.add(l);
                sceneInfo.currentMeshes.push(l);
              });
            }
          }
        }
        group.add(m);
        group.position.z = -50
        scene.add(group);
      }); //push in
      sceneInfo.dirty = false;
    }
    if (sceneInfo.beforeAndAfterDirty) {
      //cleanup & load new meshes
      //sceneInfo.beforeAndAfterActive.forEach((m) => group2.remove(m)); //pop out
      sceneInfo.currentLastMeshes.forEach((m) => group2.remove(m)); //pop out
      sceneInfo.currentLastMeshes = sceneInfo.beforeAndAfterActive; //promote active to current
      sceneInfo.beforeAndAfterActive = [];
      sceneInfo.currentLastMeshes.forEach((m, i) => {
        if (m.material) m.material.shininess = 0;
        else if (m.children) {
          //TODO filter for teeth only (not needed now but it would be futureproof)
          m.children.forEach((cm) => {
            cm.material.shininess = 0;
          });

          if (manifestVersion >= 2) {
            if (sceneInfo.isShowNumbers) {
              const labels = placeLabelsOnTeeth(m.children);
              labels.forEach((l) => {
                group2.add(l);
                sceneInfo.currentLastMeshes.push(l);
              });
            }
            if (sceneInfo.isIpr) {
              const iprs = placeIPRLabelsBetweenTeeth(
                m.children,
                sceneInfo.step
              );
              iprs.forEach((l) => {
                group2.add(l);
                sceneInfo.currentLastMeshes.push(l);
              });
            }
          }
        }
        group2.add(m);
        group2.position.z = -50
        scene2.add(group2);
      }); //push in
      sceneInfo.beforeAndAfterDirty = false;
    }
    if (sceneInfo.superImposeDirty) {
      //cleanup & load new meshes
      sceneInfo.firstStepMeshes.forEach((m) => group.remove(m)); //pop out
      sceneInfo.firstStepMeshes = sceneInfo.superImposeActive; //promote active to current
      sceneInfo.superImposeActive = [];
      sceneInfo.firstStepMeshes.forEach((m) => {
        const transformMaterial = (m) => {
          m.material.color = { r: 0, g: 0, b: 1 };
          m.material.transparent = true;
          m.material.opacity = 0.4;
          m.material.shininess = 0;
        };

        if (m.material) {
          transformMaterial(m);
        } else if (m.children) {
          m.children.forEach((cm) => transformMaterial(cm));
        }
        group.add(m);
      }); //push in
      sceneInfo.superImposeDirty = false;
      sceneInfo.removeFirstSteps = false;
    }

    if (sceneInfo.isSplit) {
      camera.aspect = canvas.clientWidth / 2 / canvas.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(canvas.clientWidth, canvas.clientHeight);
      renderer.setViewport(0, 0, canvas.clientWidth / 2, canvas.clientHeight);
      renderer.setScissor(0, 0, canvas.clientWidth / 2, canvas.clientHeight);
      renderer.setScissorTest(true);
      renderer.render(scene, camera);
      /*-----------------------------*/
      renderer.setViewport(
        canvas.clientWidth / 2,
        0,
        canvas.clientWidth / 2,
        canvas.clientHeight
      );
      renderer.setScissor(
        canvas.clientWidth / 2,
        0,
        canvas.clientWidth / 2,
        canvas.clientHeight
      );
      renderer.setScissorTest(true);
      renderer.render(scene2, camera);
    } else {
      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setViewport(0, 0, canvas.clientWidth, canvas.clientHeight);
      renderer.setScissor(0, 0, canvas.clientWidth, canvas.clientHeight);
      renderer.setScissorTest(false);
      renderer.setSize(
        (canvas.clientWidth * pixelRatio) | 0,
        (canvas.clientHeight * pixelRatio) | 0,
        false
      );
      renderer.render(scene, camera);
    }
    if (sceneInfo.isGrid) {
      
      helperGrid.visible= true
      helperGrid2.visible= true
      helperGrid3.visible= true
      helperGrid4.visible= true
    } else {
   
      helperGrid.visible= false
      helperGrid2.visible= false
      helperGrid3.visible= false
      helperGrid4.visible= false
    }
    renderer.setClearColor(0x000000, 0);
    requestAnimationFrame(render);
  }

  requestAnimationFrame(render);
};

export default {
  initializeAndRun: initialize,
  setMeshes: setMeshes,
  views: views,
  changeSceneBackgroundColor: changeSceneBackgroundColor,
  dollyIn: dollyIn,
  dollyOut: dollyOut,
  resetZoom: resetZoom,
  zoomValue: zoomValue,
  resetPosition: resetPosition,
  handleGridSize: handleGridSize,
};
