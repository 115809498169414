import React, { useEffect, useRef, useState, useCallback } from "react";
import { useGesture } from "react-use-gesture"
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import GenerateLabelsUpper from "./bounty-components/generate-labels_upper";
import GenerateLabelsLower from "./bounty-components/generate-labels-lower";
import PlayPauseAnimate from "./bounty-components/animate-steps";
import GenerateSteps from "./bounty-components/generate-steps";
import {
  resolvedData,
  howManyTsFiles,
  resolvePromise,
  generateStepsForMeshes,
  handlingconfigrasions
} from "../TS-Helper";

import loader from "../TS-VIEWER/loader";
import { createScene, meshesForStep } from "../TS-VIEWER/Scene";
import viewer from "../TS-VIEWER/viewer";
import {
  updateMeshesAccordingToStepNumber,
  indicatorTracking,
} from "./bounty-functionality";
import ProgressBar from "./bounty-components/progress-bar";
import ViewerNotFound from "./bounty-components/ts-not-found";
import Nav from "./bounty-components/nav" 

import { themeConfig } from "../TS-Helper/themeConfigrasions";

import  "../bounty-template/bounty-style/bounty.scss";

import { Helmet } from "react-helmet";
import { iprData } from "../TS-VIEWER/iprdata";
function BountyTemplate(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const { caseId, tsId, doctorId, showBiteRecord=false, position, modalViewer,
    isAttachment=true, isZoom = true, isAdmin, height='100vh', isExpand=true } = props;
  const tsContaner = useRef(null);
  const renderCanvas = useRef(null);
  const currentWidthStepsRef = useRef(null);
  
  const stepsRef = useRef([]);
  const rangeRef = useRef(null);
  
  const [tsOptions, setTSOptions] = useState({
    //default options
    STEPS_PAUSE: 500,
    showUpperArc: true,
    showLowerArc: true,
    showUpper: true, // for maxilla
    showLower: true, // for mandible
    loading: true,
    isTSViewerFound: true,
    isAnimateStart: false,
    hasSuperImpose: true,
    isLoaded: false,
    isTsPrepared: false,
    isZoom: true,
    viewerId: null,
    isDarkMode: false,
    isAttachment: true, // for toggle attachments
    isSuperImpose: false,
    isIpr: false, // for toggle IPR
    isShowNumbers: false, // for toggle Teeth Numbering/Naming
    isM: false,
    themeColor: '#44e2d0',
    cursor: 'grab',
    steps: [],
    isSplit: false,
    hideStepper: false,
    resetMesh: false,
    isGrid: false
  });

  let handlingTeethPerStep = () => {
    const upperSteps = stepsOptions.upperSteps
    const lowerSteps = stepsOptions.lowerSteps
    let steps
    if(stepsOptions.startTogether) {
      steps = Array.from({length: meshesForStep.length}, (v, i) => (
        { u: i < upperSteps ? true : false, l: i < lowerSteps ? true : false}
      ))
    } else if(!stepsOptions.startTogether) { 
      steps = Array.from({length: meshesForStep.length}, (v, i) => (
        upperSteps > lowerSteps ? {u: i < upperSteps ? true : false, l: i < (upperSteps - lowerSteps) ? false : true} :
        { u: i < (lowerSteps - upperSteps) ? false : true, l: i < lowerSteps ? true : false}
      ))
    }
    setTSOptions((prevTSOptions) => ({
      ...prevTSOptions,
      steps: steps
    }));
  }

  const [stepsOptions, setStepsOptions] = useState(0);
  const [zoom, setZoom] = useState(100)
  
  const [hover, setHover] = useState(false)
  const [tooltipActive, setTooltipActive] = useState(false);
  const [expand, setExpand] = useState(false)

  const [progress, setProgress] = useState(0);
  const [countProgress, setCountProgress] = useState(1);
  const [currentStep, setCurrentStep] = useState(0);
  const [preveStep, setPreveStep] = useState(currentStep)
  const [rangeValue, setRangeValue] = useState(0)
  const [pinchY, setPinchY] = useState(0)
  const [width, setWidth] = useState(window.innerWidth)

  const handle = useFullScreenHandle();
  
  const [indicatorWidth, setIndicatorWidth] = useState(
    indicatorTracking(currentStep, meshesForStep, currentWidthStepsRef)
  );

  let handleCursor = (cursor) => {
    if (cursor === 'grab') {return 'cursor_grab'}
    if (cursor === 'move') {return 'cursor_move'}
    if (cursor === 'zoomIn') {return 'cursor_zoomIn'}
    if (cursor === 'zoomOut') {return 'cursor_zoomOut'}
  }
  let maxStepsNumber = meshesForStep.length - 1

  const handleZoomIn = () => {
    if (zoom < 500 ) {
      viewer.dollyIn(1)
      setZoom(zoom + 25)
  } else return;
}

const handleZoomOut = () => {
  if ( zoom > 25 ) {
    viewer.dollyOut(1)
    setZoom(zoom - 25)
} else return;
}

let handleZoom = () => {
  if (tsOptions.cursor === 'zoomIn') {
    if (zoom < 500 ) {
      viewer.dollyIn(1)
      setZoom(zoom + 25)
  } else return;
  }
  if (tsOptions.cursor === 'zoomOut') {
    if ( zoom > 25 ) {
        viewer.dollyOut(1)
        setZoom(zoom - 25)
    } else return;
  }
}


  let preparingTSViewer = useCallback( async () => {
    try {
      const {viewerId, isDarkMode, isAttachment, isSuperImpose, isAccess, caseId, tsId, doctorId, files, isM, isAdmin} = await handlingconfigrasions(setTSOptions, props);
      const resolver = await resolvePromise(viewerId, isAccess, caseId, tsId, doctorId, files, isM, isAdmin);
      let { data } = await resolvedData(resolver('manifest.json'));

      const manifestVersion = data.manifestVersion || 1;
      // const iprFile = resolver('IPR.json');

      // let IPRs;
      // if(iprFile) {
      //   if(manifestVersion <2) {
      //     console.warn("not loading IPR file specified with manifest version < 2.")
      //   }
      //   IPRs = (await resolvedData(iprFile))?.data;
      // }

        const lowerSteps = data["lowerStepsNumber"];
        const upperSteps = data["upperStepsNumber"];
        const startTogether = data["startTogether"];
        let passiveAligners = data["passiveAligners"];
        let upperOvercorrectionStepsNumber =
          data["upperOvercorrectionStepsNumber"];
        let lowerOvercorrectionStepsNumber =
          data["lowerOvercorrectionStepsNumber"];
        if (lowerSteps < upperSteps) {
          // override lowerOvercorrectionStepsNumber
          lowerOvercorrectionStepsNumber = data["lowerOvercorrectionStepsNumber"];
            
        } if (upperSteps < lowerSteps) {
          // override upperOvercorrectionStepsNumber
          upperOvercorrectionStepsNumber = data["upperOvercorrectionStepsNumber"];
        }
      setStepsOptions({
        lowerSteps,
        upperSteps,
        startTogether,
        passiveAligners,
        lowerOvercorrectionStepsNumber,
        upperOvercorrectionStepsNumber,
      })
     
      let howManyFiles = howManyTsFiles(data, true, tsOptions.hasSuperImpose);
      setCountProgress(howManyFiles);
      generateStepsForMeshes(lowerSteps, upperSteps);
      let scene = createScene(lowerSteps, upperSteps, startTogether);
      await loader(
        resolver,
        data,
        scene,
        setProgress,
        setCountProgress,
        setTSOptions,
        tsOptions.isAttachment,
        tsOptions.hasSuperImpose,
      )
      updateMeshesAccordingToStepNumber(currentStep, tsOptions);
        setTSOptions((prevTSOptions) => ({
        ...prevTSOptions,
        isTsPrepared: false,
        loading: false,
        isLoaded: true,
      }));   
      
      viewer.initializeAndRun(renderCanvas.current, {
        action: "bounty-template"}, tsOptions, manifestVersion, iprData);
    }
    catch(err) {
      //  Treatment plan not found
      setTSOptions((prevTSOptions) => ({
        ...prevTSOptions,
        loading: false,
        isTSViewerFound: false,
        isTsPrepared: false,
        isLoaded: true,
      }));
      console.log(err, "Treatment plan not found"); 
    }

  },[caseId, tsId, doctorId])


  const handleResizeContainer = () => {
    setWidth(window.innerWidth)
    setIndicatorWidth(
      indicatorTracking(currentStep, meshesForStep, currentWidthStepsRef)
    );
  };



  useEffect(() => {
    window.addEventListener("resize", handleResizeContainer);
    window.addEventListener("resize", viewer.zoomValue);
    handleResizeContainer();
  }, [expand]);
  useEffect(() => {
    if (!handle.active) setExpand(false)
      else if (!handle.active) setExpand(true)
  }, [handle.active]);

  useEffect(() => {
      if (!tsOptions.isLoaded) {
          preparingTSViewer();
      } else {
        handlingTeethPerStep()
      }
  }, [tsOptions.isLoaded, tsOptions.cursor]);

  useEffect(() => {
    //themeConfig(tsOptions.themeColor)
    setIndicatorWidth(
      indicatorTracking(currentStep, meshesForStep, currentWidthStepsRef)
    );
  }, [
    currentStep,
    tsOptions.showUpperArc,
    tsOptions.showLowerArc,
    tsOptions.showUpper,
    tsOptions.showLower,
    tsOptions.isAttachment,
    tsOptions.isSuperImpose,
    tsOptions.isIpr,
    tsOptions.isShowNumbers,
    tsOptions.isAnimateStart,
    meshesForStep.length,
  ]);
  useEffect(() => {
    if (meshesForStep.length && tsOptions.isLoaded) {
      updateMeshesAccordingToStepNumber(currentStep, tsOptions);
    }
   if (tsOptions.isSplit) {
    setCurrentStep(0)
   }
  }, [
    currentStep,
    tsOptions.isLoaded,
    tsOptions.showUpperArc,
    tsOptions.showLowerArc,
    tsOptions.showUpper,
    tsOptions.showLower,
    tsOptions.isAttachment,
    tsOptions.isSuperImpose,
    tsOptions.isIpr,
    tsOptions.isShowNumbers,
    tsOptions.isAnimateStart,
    tsOptions.isSplit,
    tsOptions.cursor,
    tsOptions.isGrid,
    modalViewer
  ]);
  useEffect(() => {
    viewer.resetPosition()
  }, [
    tsOptions.resetMesh
  ]);
  useEffect(() => {
    viewer.handleGridSize(zoom)
  }, [zoom]);

  useEffect(() => {
    if (tsOptions.isLoaded && tsOptions.isTSViewerFound) {
      if (showBiteRecord) {
        viewer.views(position) 
        setTSOptions((prevOptions) => {
          return { ...prevOptions,isIpr: false,isShowNumbers: false, isSuperImpose: false, showLowerArc: true,
            showUpperArc: true, showUpper: true, showLower: true,
          };
        });
      } else {
        viewer.views("front")
      }
    }
  }, [tsOptions.isLoaded, showBiteRecord, position])
  useEffect(() => {
    return () => {
      viewer.resetZoom()
      meshesForStep.filter(() => {
        return meshesForStep.splice(0);
      })
    }
    
  }, [])

  return (
    <FullScreen handle={handle}>
    <div
      id="bounty_template"
      //className={expand ? 'bounty_template_full_screen': '' }
      style={{height: expand ? '100vh' : height}}
      ref={tsContaner}
      onWheel={(event) => {
        // zoom in / out on wheel
        if (  tsOptions.loading ||
          tsOptions.isTsPrepared ||
          !tsOptions.isTSViewerFound) {
            return;
        
        } else {  
          if (event.deltaY > 0) {
          handleZoomOut()
        }
        if (event.deltaY < 0) {
          handleZoomIn()
        }};
      }}>
      <Helmet>
        {/* disable zoom on mobile web page */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, 
            user-scalable=0"
        />
        <title>Plan Viewer</title>
      </Helmet>
      <div className="thewiewerwindow">
        {/* <!-- Trigger/Open The Modal --> */}
    
        
        <Nav 
        handle={handle}
          isExpand={isExpand}
          expand={expand}
          setExpand={setExpand}
          primary={tsOptions.themeColor}
          zoom={zoom}
          tsOptions={tsOptions}
          handleZoomIn={handleZoomIn}
          handleZoomOut={handleZoomOut}
          setTSOptions={setTSOptions}
        />
 
      <canvas  className={`rendercanvas ${handleCursor(tsOptions.cursor)} ${!tsOptions.isGrid && 'grid_bg'}`} ref={renderCanvas} onClick={handleZoom}></canvas>
  
 
        {/* Progress bar  */}
        <ProgressBar
          loading={tsOptions.loading}
          progress={progress}
          countProgress={countProgress}
          isTsPrepared={tsOptions.isTsPrepared}
          frontView={viewer.views}
        />
        {/* Not found viewer  */}
        <ViewerNotFound isTSViewerFound={tsOptions.isTSViewerFound} />
            {/* <!-- Steps container --> */}
    {<div
          id={"steps_container"}
          style={
            tsOptions.loading ||
            tsOptions.isTsPrepared ||
            !tsOptions.isTSViewerFound
              ? { zIndex: "-2", display:"none" }
              :  tsOptions.isSplit ? {height: '0px'} : { zIndex: "2" }
          }
        >
        {/* <!-- Play/Pause animate --> */}
        <PlayPauseAnimate
          maxStepsNumber={maxStepsNumber}
          width={width}
          isDarkMode={tsOptions.isDarkMode}
          tsOptions={tsOptions}
          setTSOptions={setTSOptions}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          meshesForStep={meshesForStep}
          preveStep={preveStep}
          setPreveStep={setPreveStep}
          stepsRef={stepsRef}
        />
          <div className="steps"
            ref={rangeRef}
          >

 <div className="total_steps">
  {`${currentStep}/${maxStepsNumber}`}
  </div>
          <div className={"steps_content"} ref={currentWidthStepsRef}>
          {tooltipActive  ? <div
                    className="tooltip_upper" 
                    style={{
                      left: indicatorTracking(
                        currentStep,
                        meshesForStep,
                        currentWidthStepsRef
                      )}}
                  >{currentStep}</div> : null}
                   {!tooltipActive ?<div style={{
                      left: indicatorTracking(
                        currentStep,
                        meshesForStep,
                        currentWidthStepsRef
                      )}} className={"current_step"}>
                {currentStep}
                </div> : null}
            {/* <!-- Generate upper steps --> */}
            <GenerateSteps
            tooltipActive={tooltipActive}
            setTooltipActive={setTooltipActive}
            hover={hover}
            steps={tsOptions.steps}
              isTsPrepared={tsOptions.isTsPrepared}
              isLoaded={tsOptions.isLoaded}
              isTSViewerFound={tsOptions.isTSViewerFound}
              meshesForStep={meshesForStep}
              setCurrentStep={setCurrentStep}
              loading={tsOptions.loading}
              lowerSteps={stepsOptions.lowerSteps}
              upperSteps={stepsOptions.upperSteps}
              allStepsForUpper={stepsOptions.upperSteps} // all upper steps
              allStepsForLower={stepsOptions.lowerSteps} // all upper steps
              startTogether={stepsOptions.startTogether}
              passiveAligners={stepsOptions.passiveAligners}
              upperOverCorrectionStepsNumber={
                stepsOptions.upperOvercorrectionStepsNumber // overcorrection for upper steps
              }
              lowerOverCorrectionStepsNumber={
                stepsOptions.lowerOvercorrectionStepsNumber // overcorrection for upper steps
              }
              currentStep={currentStep}
              setHover={setHover}
              stepsRef={stepsRef}
              preveStep={preveStep}
              setPreveStep={setPreveStep}
              isAnimateStart={tsOptions.isAnimateStart}
              setRangeValue={setRangeValue}
            />
          </div>
        </div>
      </div>}
      </div> 

    </div>
    </FullScreen>
  );
}

export default BountyTemplate;