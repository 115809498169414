import React from "react";


export default function TSNotFound(props) {
  const { isTSViewerFound } = props;
  return (
    <div
      className={"viewer_not_found"}
      style={isTSViewerFound ? { display: "none" } : { display: "block" }}
    >
      <div className={"viewer_not_found_text"}>
        Treatment plan not found
      </div>
    </div>
  );
}
