import axios from "axios";
import { fetchTSUrlsForEonAcess, fetchTSUrlsForEonMfg, fetchTSUrls } from "../TS-VIEWER/TS-API";

let isCompleted = false;
let prevCompNum;
const onDownloadProgress = (
  progressEvent,
  setProgress,
  setCountProgress,
  setTSOptions
) => {
  if (prevCompNum && prevCompNum <= 3) {
    if (setTSOptions) {
      setTSOptions((prevTSOptions) => ({
        ...prevTSOptions,
        loading: false,
        isTsPrepared: true,
      }));
    }

    return;
  }
  let percentCompleted = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total
  );
  isCompleted = progressEvent.lengthComputable;
  if (setProgress && setCountProgress) {
    if (percentCompleted >= 100 && isCompleted) {
      setProgress(percentCompleted);
      setCountProgress((prevCountProgress) => {
        if (prevCountProgress <= 2) {
          if (setTSOptions) {
            setTSOptions((prevTSOptions) => ({
              ...prevTSOptions,
              loading: false,
              isTsPrepared: true,
            }));
          }

          return 1;
        } else {
          prevCompNum = prevCountProgress;
          return prevCountProgress - 1;
        }
      });
      isCompleted = false;
    }
  }
};
const apiResolver = async ( viewerId, isAccess, caseId, tsId, doctorId, isM, files, isAdmin) => {
  try {
    let response = isM ? isAccess ? await fetchTSUrlsForEonAcess(viewerId) : await fetchTSUrlsForEonMfg(viewerId) : isAdmin ? null : await fetchTSUrls(caseId, tsId, doctorId)
    const urls = isM ? response?.data?.urls ?? [] : isAdmin ? files : response?.data?.files ?? [];
   
    const processedUrls = {};
    for (let i = 0; i < urls.length; i++) {
      let fullUrl = urls[i];
      let parts = fullUrl.split("?");
      let url = parts[0];
      let urlPatrs = url.split("/");
      let filename = urlPatrs[urlPatrs.length - 1]; // the last
      processedUrls[filename] = fullUrl;
    }
    return (m) => processedUrls[m];
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("response error message =>", error.message);
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("request error message =>", error.message);
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
  }
};

const resolvedData = (
  resolve,
  responseType = {},
  setProgress,
  setCountProgress,
  setTSOptions
) => {
  return axios.get(resolve, {
    onDownloadProgress: (progressEvent) =>
      onDownloadProgress(
        progressEvent,
        setProgress,
        setCountProgress,
        setTSOptions
      ),
    ...responseType,
  });
};

export { apiResolver, resolvedData };
