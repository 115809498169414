export const stepsStyleValidation = (
  stepNumber,
  lowerSteps,
  upperSteps,
  allStepsForUpper,
  allStepsForLower,
  startTogether,
  passiveAligners,
  upperOverCorrectionStepsNumber,
  lowerOverCorrectionStepsNumber,
  isUpper
) => {
  let styleArray = [];
  if(startTogether) {
    if (isUpper) {
      if(stepNumber < allStepsForUpper - upperOverCorrectionStepsNumber) {
        styleArray.push('step');
      } else {
       
        upperOverCorrectionStepsNumber === 0 ? styleArray.push('step') : styleArray.push('overCorrectionStepsNumber')
      }
     } else {
      if(stepNumber < allStepsForLower - lowerOverCorrectionStepsNumber) {
        styleArray.push('step');
      } else {
        lowerOverCorrectionStepsNumber === 0 ? styleArray.push('step') : styleArray.push('overCorrectionStepsNumber')
      }
     }
  } else {
    if (isUpper) {
      if (allStepsForUpper === 1) {passiveAligners? styleArray.push('passiveAligners') : styleArray.push('emptyStep')}
      if (allStepsForUpper === allStepsForLower) {
        if(stepNumber < allStepsForUpper - upperOverCorrectionStepsNumber) {
          styleArray.push('step');
        } else {
          upperOverCorrectionStepsNumber === 0 ? styleArray.push('step') : styleArray.push('overCorrectionStepsNumber')
        }
      }
      if (allStepsForUpper < allStepsForLower) {
        if (stepNumber <= allStepsForLower- allStepsForUpper) {
          styleArray.push('step');
        }
        else if (stepNumber >= allStepsForLower- allStepsForUpper && stepNumber < allStepsForLower - upperOverCorrectionStepsNumber ) {
          styleArray.push('step');
        } else {
          upperOverCorrectionStepsNumber === 0 ? styleArray.push('step') : styleArray.push('overCorrectionStepsNumber')
        }
      } else {
        if (stepNumber <= allStepsForUpper- allStepsForLower) {
          styleArray.push('step');
        }
        else if (stepNumber >= allStepsForUpper- allStepsForLower && stepNumber < allStepsForUpper - upperOverCorrectionStepsNumber) {
          styleArray.push('step');
        } else {
          upperOverCorrectionStepsNumber === 0 ? styleArray.push('step') : styleArray.push('overCorrectionStepsNumber')
        }
      }
    } else {
      if (allStepsForLower === 1) {passiveAligners? styleArray.push('passiveAligners') : styleArray.push('emptyStep')}
      if (allStepsForLower === allStepsForUpper) {
        if(stepNumber < allStepsForLower - lowerOverCorrectionStepsNumber) {
          styleArray.push('step');
        } else {
          lowerOverCorrectionStepsNumber === 0 ? styleArray.push('step') : styleArray.push('overCorrectionStepsNumber')
        }
      }
      if (allStepsForUpper < allStepsForLower) {
        if (stepNumber <= allStepsForLower- allStepsForUpper) {
          styleArray.push('step');
        }
        else if (stepNumber >= allStepsForLower- allStepsForUpper && stepNumber < allStepsForLower - lowerOverCorrectionStepsNumber) {
          styleArray.push('step');
        } else {
          lowerOverCorrectionStepsNumber === 0 ? styleArray.push('step') : styleArray.push('overCorrectionStepsNumber')
        }
      } else {
        if (stepNumber <= allStepsForUpper- allStepsForLower) {
          styleArray.push('step');
        }
        else if (stepNumber >= allStepsForUpper- allStepsForLower && stepNumber < allStepsForUpper - lowerOverCorrectionStepsNumber) {
          styleArray.push('step');
        } else {
          lowerOverCorrectionStepsNumber === 0 ? styleArray.push('step') : styleArray.push('overCorrectionStepsNumber')
        }
      }
    }
  }
  return styleArray.join(" ");
};