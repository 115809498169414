export const iprData = {
    lower_teeth: {
      lower_r_8: {
        value: false,
  
        key: 1,
  
        index: 8,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        fdi_key: 48,
  
        tooth_key: "LR_8 / LR_7",
  
        ipr_steps_amounts: [
          {
            step: 2,
  
            amount: 0.1,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LR8",
  
          fdi: 48,
  
          universal: 32,
        },
  
        tooth_key_sys: {
          palmer: "LR8 / LR7",
  
          fdi: "48 / 47",
  
          universal: "32 / 31",
        },
      },
  
      lower_r_7: {
        value: false,
  
        key: 2,
  
        index: 7,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        fdi_key: 47,
  
        tooth_key: "LR_7 / LR_6",
  
        ipr_steps_amounts: [
          {
            step: 2,
  
            amount: 0.1,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LR7",
  
          fdi: 47,
  
          universal: 31,
        },
  
        tooth_key_sys: {
          palmer: "LR7 / LR6",
  
          fdi: "47 / 46",
  
          universal: "31 / 30",
        },
      },
  
      lower_r_6: {
        value: false,
  
        key: 3,
  
        index: 6,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: true,
  
        do_not_perform_ipr: false,
  
        fdi_key: 46,
  
        tooth_key: "LR_6 / LR_5",
  
        ipr_steps_amounts: [
          {
            step: 1,
  
            amount: 0.6,
          },
          {
            step: 2,
  
            amount: 0.2,
          },
          {
            step: 4,
  
            amount: 0.2,
          },
          {
            step: 8,
  
            amount: 0.2,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LR6",
  
          fdi: 46,
  
          universal: 30,
        },
  
        tooth_key_sys: {
          palmer: "LR6 / LR5",
  
          fdi: "46 / 45",
  
          universal: "30 / 29",
        },
      },
  
      lower_r_5: {
        value: false,
  
        key: 4,
  
        index: 5,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: true,
  
        do_not_perform_ipr: false,
  
        fdi_key: 45,
  
        tooth_key: "LR_5 / LR_4",
  
        ipr_steps_amounts: [
          {
            step: 1,
  
            amount: 0.5,
          },
          {
            step: 2,
  
            amount: 0.3,
          },
          {
            step: 4,
  
            amount: 0.2,
          },
          {
            step: 8,
  
            amount: 0.2,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LR5",
  
          fdi: 45,
  
          universal: 29,
        },
  
        tooth_key_sys: {
          palmer: "LR5 / LR4",
  
          fdi: "45 / 44",
  
          universal: "29 / 28",
        },
      },
  
      lower_r_4: {
        value: false,
  
        key: 5,
  
        index: 4,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: true,
  
        do_not_perform_ipr: false,
  
        fdi_key: 44,
  
        tooth_key: "LR_4 / LR_3",
  
        ipr_steps_amounts: [
          {
            step: 1,
  
            amount: 0.4,
          },
          {
            step: 2,
  
            amount: 0.3,
          },
          {
            step: 4,
  
            amount: 0.2,
          },
          {
            step: 8,
  
            amount: 0.2,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LR4",
  
          fdi: 44,
  
          universal: 28,
        },
  
        tooth_key_sys: {
          palmer: "LR4 / LR3",
  
          fdi: "44 / 43",
  
          universal: "28 / 27",
        },
      },
  
      lower_r_3: {
        value: false,
  
        key: 6,
  
        index: 3,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: true,
  
        do_not_perform_ipr: false,
  
        fdi_key: 43,
  
        tooth_key: "LR_3 / LR_2",
  
        ipr_steps_amounts: [
          {
            step: 1,
  
            amount: 0.3,
          },
          {
            step: 2,
  
            amount: 0.3,
          },
          {
            step: 4,
  
            amount: 0.2,
          },
          {
            step: 8,
  
            amount: 0.2,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LR3",
  
          fdi: 43,
  
          universal: 27,
        },
  
        tooth_key_sys: {
          palmer: "LR3 / LR2",
  
          fdi: "43 / 42",
  
          universal: "27 / 26",
        },
      },
  
      lower_r_2: {
        value: false,
  
        key: 7,
  
        index: 2,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        fdi_key: 42,
  
        tooth_key: "LR_2 / LR_1",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LR2",
  
          fdi: 42,
  
          universal: 26,
        },
  
        tooth_key_sys: {
          palmer: "LR2 / LR1",
  
          fdi: "42 / 41",
  
          universal: "26 / 25",
        },
      },
  
      lower_r_1: {
        value: false,
  
        key: 8,
  
        index: 1,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        fdi_key: 41,
  
        tooth_key: "LR_1 / LR_0",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: true,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LR1",
  
          fdi: 41,
  
          universal: 25,
        },
  
        tooth_key_sys: {
          palmer: "LR1 / LL1",
  
          fdi: "41 / 31",
  
          universal: "25 / 24",
        },
      },
  
      lower_l_1: {
        value: false,
  
        key: 9,
  
        index: 1,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        tooth_key: "LL_1 / LL_2",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: true,
  
        fdi_key: 31,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LL1",
  
          fdi: 31,
  
          universal: 24,
        },
  
        tooth_key_sys: {
          palmer: "LL1 / LL2",
  
          fdi: "31 / 32",
  
          universal: "24 / 23",
        },
      },
  
      lower_l_2: {
        value: false,
  
        key: 10,
  
        index: 2,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        tooth_key: "LL_2 / LL_3",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        fdi_key: 32,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LL2",
  
          fdi: 32,
  
          universal: 23,
        },
  
        tooth_key_sys: {
          palmer: "LL2 / LL3",
  
          fdi: "32 / 33",
  
          universal: "23 / 22",
        },
      },
  
      lower_l_3: {
        value: false,
  
        key: 11,
  
        index: 3,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        tooth_key: "LL_3 / LL_4",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        fdi_key: 33,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LL3",
  
          fdi: 33,
  
          universal: 22,
        },
  
        tooth_key_sys: {
          palmer: "LL3 / LL4",
  
          fdi: "33 / 34",
  
          universal: "22 / 21",
        },
      },
  
      lower_l_4: {
        value: false,
  
        key: 12,
  
        index: 4,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        tooth_key: "LL_4 / LL_5",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        fdi_key: 34,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LL4",
  
          fdi: 34,
  
          universal: 21,
        },
  
        tooth_key_sys: {
          palmer: "LL4 / LL5",
  
          fdi: "34 / 35",
  
          universal: "21 / 20",
        },
      },
  
      lower_l_5: {
        value: false,
  
        key: 13,
  
        index: 5,
  
        amount: 0,
  
        before_step: "",
  
        divider: true,
  
        use_ipr: true,
  
        do_not_perform_ipr: false,
  
        tooth_key: "LL_5 / LL_6",
  
        ipr_steps_amounts: [
          {
            step: "2",
  
            amount: ".4",
          },
  
          {
            step: "3",
  
            amount: ".2",
          },
  
          {
            step: "4",
  
            amount: ".1",
          },
        ],
  
        restrict_ipr_tooth: false,
  
        fdi_key: 35,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LL5",
  
          fdi: 35,
  
          universal: 20,
        },
  
        tooth_key_sys: {
          palmer: "LL5 / LL6",
  
          fdi: "35 / 36",
  
          universal: "20 / 19",
        },
      },
  
      lower_l_6: {
        value: false,
  
        key: 14,
  
        index: 6,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        tooth_key: "LL_6 / LL_7",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        fdi_key: 36,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LL6",
  
          fdi: 36,
  
          universal: 19,
        },
  
        tooth_key_sys: {
          palmer: "LL6 / LL7",
  
          fdi: "36 / 37",
  
          universal: "19 / 18",
        },
      },
  
      lower_l_7: {
        value: false,
  
        key: 15,
  
        index: 7,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        tooth_key: "LL_7 / LL_8",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        fdi_key: 37,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LL7",
  
          fdi: 37,
  
          universal: 18,
        },
  
        tooth_key_sys: {
          palmer: "LL7 / LL8",
  
          fdi: "37 / 38",
  
          universal: "18 / 17",
        },
      },
  
      lower_l_8: {
        value: false,
  
        key: 16,
  
        index: 8,
  
        fdi_key: 38,
  
        use_ipr: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "LL8",
  
          fdi: 38,
  
          universal: 17,
        },
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        tooth_key_sys: {
          palmer: "LL8 / undefined",
  
          fdi: "38 / undefined",
  
          universal: "17 / undefined",
        },
      },
    },
  
    upper_teeth: {
      upper_r_8: {
        value: false,
  
        key: 1,
  
        index: 8,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        fdi_key: 18,
  
        tooth_key: "UR_8 / UR_7",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UR8",
  
          fdi: 18,
  
          universal: 1,
        },
  
        tooth_key_sys: {
          palmer: "UR8 / UR7",
  
          fdi: "18 / 17",
  
          universal: "1 / 2",
        },
      },
  
      upper_r_7: {
        value: false,
  
        key: 2,
  
        index: 7,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        fdi_key: 17,
  
        tooth_key: "UR_7 / UR_6",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UR7",
  
          fdi: 17,
  
          universal: 2,
        },
  
        tooth_key_sys: {
          palmer: "UR7 / UR6",
  
          fdi: "17 / 16",
  
          universal: "2 / 3",
        },
      },
  
      upper_r_6: {
        value: false,
  
        key: 3,
  
        index: 6,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: true,
  
        do_not_perform_ipr: false,
  
        fdi_key: 16,
  
        tooth_key: "UR_6 / UR_5",
  
        ipr_steps_amounts: [
          {
            step: 1,
  
            amount: 0.6,
          },
      
          {
            step: 2,
  
            amount: 0.3,
          },
          {
            step: 4,
  
            amount: 0.2,
          },
          {
            step: 8,
  
            amount: 0.2,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UR6",
  
          fdi: 16,
  
          universal: 3,
        },
  
        tooth_key_sys: {
          palmer: "UR6 / UR5",
  
          fdi: "16 / 15",
  
          universal: "3 / 4",
        },
      },
  
      upper_r_5: {
        value: false,
  
        key: 4,
  
        index: 5,
  
        amount: 0,
  
        before_step: "",
  
        divider: true,
  
        use_ipr: true,
  
        do_not_perform_ipr: false,
  
        fdi_key: 15,
  
        tooth_key: "UR_5 / UR_4",
  
        ipr_steps_amounts: [
          {
            step: 1,
  
            amount: 0.5,
          },
          {
            step: "3",
  
            amount: ".6",
          },
        ],
  
        restrict_ipr_tooth: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UR5",
  
          fdi: 15,
  
          universal: 4,
        },
  
        tooth_key_sys: {
          palmer: "UR5 / UR4",
  
          fdi: "15 / 14",
  
          universal: "4 / 5",
        },
      },
  
      upper_r_4: {
        value: false,
  
        key: 5,
  
        index: 4,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: true,
  
        do_not_perform_ipr: false,
  
        fdi_key: 14,
  
        tooth_key: "UR_4 / UR_3",
  
        ipr_steps_amounts: [
          {
            step: 1,
  
            amount: 0.4,
          },
          {
            step: 2,
  
            amount: 0.3,
          },
          {
            step: 4,
  
            amount: 0.2,
          },
          {
            step: 8,
  
            amount: 0.2,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UR4",
  
          fdi: 14,
  
          universal: 5,
        },
  
        tooth_key_sys: {
          palmer: "UR4 / UR3",
  
          fdi: "14 / 13",
  
          universal: "5 / 6",
        },
      },
  
      upper_r_3: {
        value: false,
  
        key: 6,
  
        index: 3,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: true,
  
        do_not_perform_ipr: false,
  
        fdi_key: 13,
  
        tooth_key: "UR_3 / UR_2",
  
        ipr_steps_amounts: [
          {
            step: 1,
  
            amount: 0.3,
          },
          {
            step: 2,
  
            amount: 0.3,
          },
          {
            step: 4,
  
            amount: 0.2,
          },
          {
            step: 8,
  
            amount: 0.2,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UR3",
  
          fdi: 13,
  
          universal: 6,
        },
  
        tooth_key_sys: {
          palmer: "UR3 / UR2",
  
          fdi: "13 / 12",
  
          universal: "6 / 7",
        },
      },
  
      upper_r_2: {
        value: false,
  
        key: 7,
  
        index: 2,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        fdi_key: 12,
  
        tooth_key: "UR_2 / UR_1",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UR2",
  
          fdi: 12,
  
          universal: 7,
        },
  
        tooth_key_sys: {
          palmer: "UR2 / UR1",
  
          fdi: "12 / 11",
  
          universal: "7 / 8",
        },
      },
  
      upper_r_1: {
        value: false,
  
        key: 8,
  
        index: 1,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        fdi_key: 11,
  
        tooth_key: "UR_1 / UR_0",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: true,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UR1",
  
          fdi: 11,
  
          universal: 8,
        },
  
        tooth_key_sys: {
          palmer: "UR1 / UL1",
  
          fdi: "11 / 21",
  
          universal: "8 / 9",
        },
      },
  
      upper_l_1: {
        value: false,
  
        key: 9,
  
        index: 1,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: true,
  
        do_not_perform_ipr: false,
  
        tooth_key: "UL_1 / UL_2",
  
        ipr_steps_amounts: [
          {
            step: 2,
  
            amount: 0.3,
          },
          {
            step: 4,
  
            amount: 0.2,
          },
          {
            step: 8,
  
            amount: 0.2,
          },
        ],
  
        restrict_ipr_tooth: true,
  
        fdi_key: 21,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UL1",
  
          fdi: 21,
  
          universal: 9,
        },
  
        tooth_key_sys: {
          palmer: "UL1 / UL2",
  
          fdi: "21 / 22",
  
          universal: "9 / 10",
        },
      },
  
      upper_l_2: {
        value: false,
  
        key: 10,
  
        index: 2,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        tooth_key: "UL_2 / UL_3",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        fdi_key: 22,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UL2",
  
          fdi: 22,
  
          universal: 10,
        },
  
        tooth_key_sys: {
          palmer: "UL2 / UL3",
  
          fdi: "22 / 23",
  
          universal: "10 / 11",
        },
      },
  
      upper_l_3: {
        value: false,
  
        key: 11,
  
        index: 3,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        tooth_key: "UL_3 / UL_4",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        fdi_key: 23,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UL3",
  
          fdi: 23,
  
          universal: 11,
        },
  
        tooth_key_sys: {
          palmer: "UL3 / UL4",
  
          fdi: "23 / 24",
  
          universal: "11 / 12",
        },
      },
  
      upper_l_4: {
        value: false,
  
        key: 12,
  
        index: 4,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        tooth_key: "UL_4 / UL_5",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        fdi_key: 24,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UL4",
  
          fdi: 24,
  
          universal: 12,
        },
  
        tooth_key_sys: {
          palmer: "UL4 / UL5",
  
          fdi: "24 / 25",
  
          universal: "12 / 13",
        },
      },
  
      upper_l_5: {
        value: false,
  
        key: 13,
  
        index: 5,
  
        amount: 0,
  
        before_step: "",
  
        divider: true,
  
        use_ipr: true,
  
        do_not_perform_ipr: false,
  
        tooth_key: "UL_5 / UL_6",
  
        ipr_steps_amounts: [
          {
            step: "2",
  
            amount: ".5",
          },
        ],
  
        restrict_ipr_tooth: false,
  
        fdi_key: 25,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UL5",
  
          fdi: 25,
  
          universal: 13,
        },
  
        tooth_key_sys: {
          palmer: "UL5 / UL6",
  
          fdi: "25 / 26",
  
          universal: "13 / 14",
        },
      },
  
      upper_l_6: {
        value: false,
  
        key: 14,
  
        index: 6,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        tooth_key: "UL_6 / UL_7",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        fdi_key: 26,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UL6",
  
          fdi: 26,
  
          universal: 14,
        },
  
        tooth_key_sys: {
          palmer: "UL6 / UL7",
  
          fdi: "26 / 27",
  
          universal: "14 / 15",
        },
      },
  
      upper_l_7: {
        value: false,
  
        key: 15,
  
        index: 7,
  
        amount: "",
  
        before_step: "",
  
        divider: true,
  
        use_ipr: false,
  
        do_not_perform_ipr: false,
  
        tooth_key: "UL_7 / UL_8",
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        fdi_key: 27,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UL7",
  
          fdi: 27,
  
          universal: 15,
        },
  
        tooth_key_sys: {
          palmer: "UL7 / UL8",
  
          fdi: "27 / 28",
  
          universal: "15 / 16",
        },
      },
  
      upper_l_8: {
        value: false,
  
        key: 16,
  
        index: 8,
  
        fdi_key: 28,
  
        use_ipr: false,
  
        updated: false,
  
        ToothSystemName: {
          palmer: "UL8",
  
          fdi: 28,
  
          universal: 16,
        },
  
        ipr_steps_amounts: [
          {
            step: 0,
  
            amount: 0,
          },
        ],
  
        restrict_ipr_tooth: false,
  
        tooth_key_sys: {
          palmer: "UL8 / undefined",
  
          fdi: "28 / undefined",
  
          universal: "16 / undefined",
        },
      },
    },
  };
  