import React, {useState, useEffect} from "react";
import viewer from "../../TS-VIEWER/viewer";

import {
    ZoomIn, ZoomOut, Attachment, SuperImpose,
    Left, Lower, Right, Upper, Bottom, Top, Front,
    Pan, Rotate, Numbering, Grid, Split, Occ, Ipr, Tools, Arrow, Expand, Shrink, Close
} from "../svg";

export default function Nav(props) {
    const {
        tsOptions,
        setTSOptions,
        isExpand,
        setExpand,
        expand,
        handle,
        handleZoomOut,
        handleZoomIn
    } = props;

    const [viewActiveTs, setViewActiveTs] = useState("front");
    const [viewerControls, setViewerControls] = useState("rotate");

    
  const handleExpand = () => {
    setExpand(!expand)
    if(expand === true) handle.exit() 
      else handle.enter()
  }


    const onTsViewChange = (action) => {
        switch (action.viewActiveTs) {
            case "left-view":
                if (!tsOptions.isTSViewerFound) return;
                setViewActiveTs("left");
                setTSOptions((prevOptions) => ({
                    ...prevOptions,
                    showLowerArc: true,
                    showUpperArc: true,
                    showUpper: true,
                    showLower: true,
                    }));
                viewer.views('left', tsOptions.isSplit)
                break;
            case "right-view":
                if (!tsOptions.isTSViewerFound) return;
                setViewActiveTs("right");
                setTSOptions((prevOptions) => ({
                    ...prevOptions,
                    showLowerArc: true,
                    showUpperArc: true,
                    showUpper: true,
                    showLower: true,
                    }));
                viewer.views('right', tsOptions.isSplit)
                break;
            case "front-view":
                if (!tsOptions.isTSViewerFound) return;
                setViewActiveTs("front");
                setTSOptions((prevOptions) => ({
                    ...prevOptions,
                    showLowerArc: true,
                    showUpperArc: true,
                    showUpper: true,
                    showLower: true,
                    }));
                viewer.views('front', tsOptions.isSplit)
                break;
                case "upper-view":
            if (!tsOptions.isTSViewerFound) return;
            setViewActiveTs("upper");
            setTSOptions((prevOptions) => ({
            ...prevOptions,
            showLowerArc: true,
            showUpperArc: false,
            showUpper: false,
            showLower: true,
            }));
            viewer.views('upper', tsOptions.isSplit)
            break;
        case "lower-view":
            if (!tsOptions.isTSViewerFound) return;
            setViewActiveTs("lower");
            setTSOptions((prevOptions) => ({
            ...prevOptions,
            showLowerArc: false,
            showUpperArc: true,
            showUpper: true,
            showLower: false,
            }));
            viewer.views('lower', tsOptions.isSplit)
            break
                case "maxilla-view":
                    if (!tsOptions.isTSViewerFound) return;
                    setTSOptions((prevOptions) => {
                        return { ...prevOptions, showLower: !prevOptions.showLower };
                });
                    break;
                case "mandible-view":
                    if (!tsOptions.isTSViewerFound) return;
                    setTSOptions((prevOptions) => {
                        return { ...prevOptions, showUpper: !prevOptions.showUpper };
                    });
                break;
                //viewer.views('bottom', tsOptions.isSplit)
                break;
            case "attachments-view":
                if (!tsOptions.isTSViewerFound) return;
                setTSOptions((prevOptions) => {
                    return { ...prevOptions, isAttachment: !prevOptions.isAttachment };
                });
                break;
            case "superImpose-view":
                if (!tsOptions.isTSViewerFound) return;
                setTSOptions((prevOptions) => {
                    return { ...prevOptions, isSuperImpose: !prevOptions.isSuperImpose };
                });
                break;
            case "ipr-view":
                if (!tsOptions.isTSViewerFound) return;
                setTSOptions((prevOptions) => {
                    return { ...prevOptions, isIpr: !prevOptions.isIpr };
                });
                break;
            case "numbers-view":
                if (!tsOptions.isTSViewerFound) return;
                setTSOptions((prevOptions) => {
                    return { ...prevOptions, isShowNumbers: !prevOptions.isShowNumbers };
                });
                break;
            case "split-view":
                if (!tsOptions.isTSViewerFound) return;
                setTSOptions((prevOptions) => {
                    return { ...prevOptions, isSplit: !prevOptions.isSplit, resetMesh: !prevOptions.resetMesh };
                });
                break;
            case "grid-view":
                if (!tsOptions.isTSViewerFound) return;
                setTSOptions((prevOptions) => {
                    return { ...prevOptions, isGrid: !prevOptions.isGrid };
                });
                break;
            case "rotate-view":
                if (!tsOptions.isTSViewerFound) return;
                setViewerControls('rotate')
                setTSOptions((prevOptions) => {
                    return { ...prevOptions, cursor: 'grab' };
                });
                break;
            case "pan-view":
                if (!tsOptions.isTSViewerFound) return;
                setViewerControls('pan')
                setTSOptions((prevOptions) => {
                    return { ...prevOptions, cursor: 'move' };
                });
                break;
            case "zoomIn-view":
                if (!tsOptions.isTSViewerFound) return;
                setViewerControls('zoomIn')
                setTSOptions((prevOptions) => {
                    return { ...prevOptions, cursor: 'zoomIn' };
                });
                break;
            case "zoomOut-view":
                if (!tsOptions.isTSViewerFound) return;
                setViewerControls('zoomOut')
                setTSOptions((prevOptions) => {
                    return { ...prevOptions, cursor: 'zoomOut' };
                });
                break;
            default:
                if (!tsOptions.isTSViewerFound) return;
                setViewActiveTs("front");
                setTSOptions((prevOptions) => ({
                    ...prevOptions,
                    showLowerArc: true,
                    showUpperArc: true,
                }));
                viewer.views('front', tsOptions.isSplit)
        }
    };
    const [width, setWidth] = useState(window.innerWidth)
    const handleResizeNav = () => {
        setWidth(window.innerWidth)
      };
    useEffect(() => {
        window.addEventListener("resize", handleResizeNav)

    },[width])

    const DropDown = () => {
        return (
            <>
           
                <div className={`nva_options_icon ${tsOptions.isSuperImpose ? "options_active" : ""}`}
                    onClick={() => {
                        if (tsOptions.loading || tsOptions.isTsPrepared) return;
                        onTsViewChange({ viewActiveTs: "superImpose-view" });
                }}>
                    <ToolTip text={'Show SuperImpose'} isOptions={true}/>
                    <SuperImpose />
                    <div>Super</div>
                </div>
                {/* <div className={`nva_options_icon ${tsOptions.isOcclusion ? "options_active" : ""}`}>
                <ToolTip text={'Show Occlusion'} isOptions={true}/>
                    <Occ />
                    <div>Occlus</div>
                </div> */}
                <div className={`nva_options_icon ${tsOptions.isGrid ? "options_active" : ""}`}
                onClick={() => {
                    if (tsOptions.loading || tsOptions.isTsPrepared) return;
                    onTsViewChange({ viewActiveTs: "grid-view" });
                }}>
                <ToolTip text={'Show Grid'} isOptions={true}/>
                    <Grid />
                    <div>Grid</div>
                </div>
                  
            
                    <div className={`nva_options_icon ${tsOptions.isSplit ? "options_active" : ""}`}
                    onClick={() => {
                        if (tsOptions.loading || tsOptions.isTsPrepared) return;
                        onTsViewChange({ viewActiveTs: "split-view" });
                }}>
                    <ToolTip text={'Show Split'} isOptions={true}/>
                    <Split />
                    <div>Split</div>
                </div>
                <div className={`nva_options_icon ${tsOptions.isShowNumbers ? "options_active" : ""}`}
                    onClick={() => {
                        if (tsOptions.loading || tsOptions.isTsPrepared) return;
                        onTsViewChange({ viewActiveTs: "numbers-view" });
                }}>
                    <ToolTip text={'Show Numbering'} isOptions={true}/>
                    <Numbering />
                    <div>Num</div>
                </div>
            </>
        )
    }
    const ToolTip = (props) => {
        return (
        <div className={`tooltip_content ${props.isOptions ? "tooltip_content_options" : ""}`}>{props.text}</div>
        )
    }
    return (
        <>
            <div
                className={"nav_container"}
                style={
                    tsOptions.loading || tsOptions.isTsPrepared
                            ? { zIndex: "-2", display:"none" }
                            : { zIndex: "2" }
                }
            >
                <div className="nav_controls">
                <div className={`nav_controls_icon ${viewerControls === 'rotate' ? 'rotate_active' : ''}`} onClick={() => {
                        if (tsOptions.loading || tsOptions.isTsPrepared) return;
                        onTsViewChange({ viewActiveTs: "rotate-view" });
                    }}>
<ToolTip text={'Rotate'} />
                        <Rotate />

                    </div>
                    <div className={`nav_controls_icon ${viewerControls === 'pan' ? 'pan_active' : ''}`} onClick={() => {
                        if (tsOptions.loading || tsOptions.isTsPrepared) return;
                        onTsViewChange({ viewActiveTs: "pan-view" });
                    }}>
                <ToolTip text={'Pan'} />
                        <Pan />

                    </div>

                    <div className={`nav_controls_icon ${viewerControls === 'zoomOut' ? 'zoomOut_active' : ''}`} onClick={() => {
                        if (tsOptions.loading || tsOptions.isTsPrepared) return
                        handleZoomOut()
                    }}>
<ToolTip text={'Zoom out'} />
                        <ZoomOut />

                    </div>
                    <div className={`nav_controls_icon ${viewerControls === 'zoomIn' ? 'zoomIn_active' : ''}`} onClick={() => {
                        if (tsOptions.loading || tsOptions.isTsPrepared) return;
                        handleZoomIn()
                    }}>
<ToolTip text={'Zoom in'} />
                        <ZoomIn />
                    </div>
                </div>
                <div className="nav_views">
                <div
                        className={`nav_views_icon ${tsOptions.showLower ? "nav_views_icon_active" :""} `}
                        onClick={() => {
                            if (tsOptions.loading || tsOptions.isTsPrepared) return;
                            onTsViewChange({ viewActiveTs: "maxilla-view" });
                        }}>
                            <ToolTip text={'Show Upper'} />
                        <Upper />
                        <div>Upper</div>
                    </div>
                    <div
                        className={`nav_views_icon ${viewActiveTs === "upper" ? "nav_views_icon_active" :""} `}
                        onClick={() => {
                            if (tsOptions.loading || tsOptions.isTsPrepared) return;
                            onTsViewChange({ viewActiveTs: "upper-view" });
                        }}>
                            <ToolTip text={'Top View'} />
                        <Top />
                        <div>Top</div>
                    </div>
                    <div
                       className={`nav_views_icon ${viewActiveTs === "right" ? "nav_views_icon_active" :""} `}
                        onClick={() => {
                            if (tsOptions.loading || tsOptions.isTsPrepared) return;
                            onTsViewChange({ viewActiveTs: "right-view" });
                        }}>
                            <ToolTip text={'Right View'} />
                        <Right />
                        <div>Right</div>
                    </div>
                    <div
                        className={`nav_views_icon ${viewActiveTs === "front" ? "nav_views_icon_active" :""} `}
                        onClick={() => {
                            if (tsOptions.loading || tsOptions.isTsPrepared) return;
                            onTsViewChange({ viewActiveTs: "front-view" });
                        }}>
                            <ToolTip text={'Front View'} />
                        <Front />
                        <div>Front</div>
                    </div>
                    <div
                        className={`nav_views_icon ${viewActiveTs === "left" ? "nav_views_icon_active" :""} `}
                        onClick={() => {
                            if (tsOptions.loading || tsOptions.isTsPrepared) return;
                            onTsViewChange({ viewActiveTs: "left-view" });
                        }}>
                            <ToolTip text={'Left View'} />
                           
                        <Left />
                        <div>Left</div>
                    </div>
                    <div
                        className={`nav_views_icon ${viewActiveTs === "lower" ? "nav_views_icon_active" :""} `}
                        onClick={() => {
                            if (tsOptions.loading || tsOptions.isTsPrepared) return;
                            onTsViewChange({ viewActiveTs: "lower-view" });
                        }}>
                            <ToolTip text={'Bottom View'} />
                       
                        <Bottom />
                        <div>Bottom</div>
                    </div>
                 
                    <div
                        className={`nav_views_icon ${tsOptions.showUpper ? "nav_views_icon_active" :""} `}
                        onClick={() => {
                            if (tsOptions.loading || tsOptions.isTsPrepared) return;
                            onTsViewChange({ viewActiveTs: "mandible-view" });
                        }}>
                            <ToolTip text={'Show Lower'} />

                        <Lower />
                        <div>Lower</div>
                    </div>
                </div>
                {width <= 980 ? null: <div className="divider"></div>}
                <div className="nva_options">
                    {width <= 980  ? null :        <>  <div
                        className={`nva_options_icon ${tsOptions.isAttachment ? "options_active" : ""}`}
                        onClick={() => {
                            if (tsOptions.loading || tsOptions.isTsPrepared) return;
                            onTsViewChange({ viewActiveTs: "attachments-view" });
                        }}>
                            <ToolTip text={'Show Attachment'} isOptions={true}/>
                        <Attachment />
                        <div>Attach</div>
                    </div>
                    <div
                         className={`nva_options_icon ${tsOptions.isIpr ? "ipr_active" : ""}`}
                        onClick={() => {
                            if (tsOptions.loading || tsOptions.isTsPrepared) return;
                            onTsViewChange({ viewActiveTs: "ipr-view" });
                        }}>
                            <ToolTip text={'Show IPR'} isOptions={true}/>
                        <Ipr />
                        <div>IPR</div>
                    </div>
                    <div  className={`nva_options_icon ${tsOptions.isSuperImpose ? "options_active" : ""}`}
                    onClick={() => {
                        if (tsOptions.loading || tsOptions.isTsPrepared) return;
                        onTsViewChange({ viewActiveTs: "superImpose-view" });
                }}>
                    <ToolTip text={'Show SuperImpose'} isOptions={true}/>
                    <SuperImpose />
                    <div>Super</div>
                </div>
                {/* <div className={`nva_options_icon ${tsOptions.isOcclusion ? "options_active" : ""}`}>
                    <ToolTip text={'Show Occlusion'} isOptions={true}/>
                    <Occ />
                    <div>Occlus</div>
                </div> */}
                <div className={`nva_options_icon ${tsOptions.isGrid ? "options_active" : ""}`}
                onClick={() => {
                    if (tsOptions.loading || tsOptions.isTsPrepared) return;
                    onTsViewChange({ viewActiveTs: "grid-view" });
                }}>
                <ToolTip text={'Show Grid'} isOptions={true}/>
                    <Grid />
                    <div>Grid</div>
                </div>
                <div className={`nva_options_icon ${tsOptions.isSplit ? "options_active" : ""}`} 
                onClick={() => {
                        if (tsOptions.loading || tsOptions.isTsPrepared) return;
                        onTsViewChange({ viewActiveTs: "split-view" });
                }}>
                    <ToolTip text={'Show Split view'} isOptions={true}/>
                    <Split />
                    <div>Split</div>
                </div>
                
              
                <div className={`nva_options_icon ${tsOptions.isShowNumbers ? "options_active" : ""}`}
                    onClick={() => {
                        if (tsOptions.loading || tsOptions.isTsPrepared) return;
                        onTsViewChange({ viewActiveTs: "numbers-view" });
                }}>
                    <ToolTip text={'Show Numbering'} isOptions={true}/>
                    <Numbering />
                    <div>Num</div>
                </div>
                </>
                }
           
            
               
                    {width <= 980 && <div className="dropdown tools_icon">
                        <div className="tools_div">
                        <Tools />
                        <div className="tools_arrow">
                        <Arrow />
                        </div>
                        </div>
                        <div className="dropdown_content">
                            <DropDown />
                        </div>
                        <div>Tools</div>
                       
                    </div>}
  
                </div>
                {isExpand && <span onClick={() => handleExpand() } className={"nav_icon_full_screen"}>
                    {!expand ?   <Expand /> : <Shrink />}
                </span>}
                <div className="canvas_container" >
        {tsOptions.isSplit && <div className="initial_plan">Initial Plan</div>}
      {tsOptions.isSplit && <div className="final_result">Final Result</div>}
      {tsOptions.isSplit && <div className="close_split" onClick={() => setTSOptions((prevOptions) => {
          return { ...prevOptions,isSplit: false,
          }})}><Close /></div>}
      {tsOptions.isSplit && <div className="screen_divider"></div>}
        </div>
            </div>
        </>
    );
}
